<!--
 * @Description: 全部调度信息
 * @Author: ChenXueLin
 * @Date: 2021-10-14 08:59:17
 * @LastEditTime: 2022-08-25 15:37:33
 * @LastEditors: ChenXueLin
-->
<template>
  <div class="content-view-wrapper pd-10">
    <div
      class="content-view section-wrapper"
      ref="sectionWrapper"
      :element-loading-background="loadingBackground"
      v-loading="loading"
    >
      <table-title @resize="handleTableTitleResize">
        <template v-slot:operateAction>
          <i class="e6-icon-history" title="历史记录" @click="toRecord"></i>
          <i
            class="e6-icon-export_line"
            title="导出"
            @click="handleExportDialog"
          ></i>
        </template>
        <template v-slot:searchForm>
          <el-form class="search-list" ref="searchForm" :model="searchForm">
            <el-form-item class="search-item--1" prop="scheduleInfoId">
              <el-input
                v-model="searchForm.scheduleInfoId"
                placeholder="调度信息ID"
                title="调度信息ID"
                maxlength="10"
                @input="
                  searchForm.scheduleInfoId = searchForm.scheduleInfoId.replace(
                    /[^0-9-]+/,
                    ''
                  )
                "
              ></el-input>
            </el-form-item>

            <el-form-item class="search-item--1" prop="scheduleInfoStatusList">
              <e6-vr-select
                v-model="searchForm.scheduleInfoStatusList"
                :data="scheduleStatusList"
                multiple
                :props="{
                  id: 'codeValue',
                  label: 'codeName'
                }"
                placeholder="调度信息状态"
                title="调度信息状态"
                clearable
              ></e6-vr-select>
            </el-form-item>
            <el-form-item class="search-item--1" prop="engineerrpId">
              <e6-vr-select
                v-model="searchForm.engineerrpId"
                :data="engineerList"
                placeholder="工程师"
                title="工程师"
                :props="{
                  id: 'employeeId',
                  label: 'employeeName'
                }"
                clearable
              ></e6-vr-select>
            </el-form-item>
            <el-form-item class="search-item--1" prop="engineerrpPhone">
              <el-input
                v-model="searchForm.engineerrpPhone"
                placeholder="工程师电话"
                title="工程师电话"
              ></el-input>
            </el-form-item>
            <el-form-item class="search-item--1" prop="corpName">
              <input-select
                v-model="searchForm.corpName"
                :data="corpList"
                placeholder="客户名称"
                title="客户名称"
                clearable
                virtual
                remote
                :is-title="true"
                @filterChange="handleLoadCorpFilter"
                :props="{
                  id: 'corpName',
                  label: 'corpName'
                }"
              ></input-select>
            </el-form-item>
            <el-form-item class="search-item--1" prop="createByName">
              <el-input
                v-model="searchForm.createByName"
                placeholder="创建人"
                title="创建人"
              ></el-input>
            </el-form-item>
            <el-form-item class="search-item--1" prop="taskNumber">
              <el-input
                v-model="searchForm.taskNumber"
                placeholder="所属任务单号"
                title="所属任务单号"
              ></el-input>
            </el-form-item>
            <el-form-item class="search-item--1" prop="scheduleTaskId">
              <el-input
                v-model="searchForm.scheduleTaskId"
                placeholder="所属调度任务ID"
                title="所属调度任务ID"
              ></el-input>
            </el-form-item>
            <el-form-item class="search-item--1" prop="taskSecondClass">
              <e6-vr-select
                v-model="searchForm.taskSecondClass"
                :data="taskSecondClassList"
                placeholder="任务单类型"
                title="任务单类型"
                multiple
                clearable
                :props="{
                  id: 'codeValue',
                  label: 'codeName'
                }"
              ></e6-vr-select>
            </el-form-item>
            <el-form-item class="search-item--2" prop="finishTime">
              <e6-date-picker
                popper-class="no-atTheMoment"
                label="完成时间"
                ref="effectiveDatePicker"
                v-model="searchForm.finishTime"
                title="完成时间"
                :picker-options="pickerOptions('searchForm.finishTime')"
                :default-time="['00:00:00', '23:59:59']"
                start-placeholder="完成时间（始）"
                end-placeholder="完成时间（止）"
              ></e6-date-picker>
            </el-form-item>
            <el-form-item class="search-item--2" prop="createTime">
              <e6-date-picker
                popper-class="no-atTheMoment"
                label="创建时间"
                ref="effectiveDatePicker"
                v-model="searchForm.createTime"
                title="创建时间"
                :picker-options="pickerOptions('searchForm.createTime')"
                :default-time="['00:00:00', '23:59:59']"
                start-placeholder="创建时间（始）"
                end-placeholder="创建时间（止）"
              ></e6-date-picker>
            </el-form-item>
            <el-form-item class="search-item--2" prop="expectTime">
              <e6-date-picker
                popper-class="no-atTheMoment"
                label="预约完成时间"
                ref="effectiveDatePicker"
                v-model="searchForm.expectTime"
                title="预约完成时间"
                :picker-options="pickerOptions('searchForm.expectTime')"
                :default-time="['00:00:00', '23:59:59']"
                start-placeholder="预约完成时间（始）"
                end-placeholder="预约完成时间（止）"
              ></e6-date-picker>
            </el-form-item>
            <el-form-item class="search-item--2" prop="signTime">
              <e6-date-picker
                popper-class="no-atTheMoment"
                label="签到时间"
                ref="effectiveDatePicker"
                v-model="searchForm.signTime"
                title="签到时间"
                :picker-options="pickerOptions('searchForm.signTime')"
                :default-time="['00:00:00', '23:59:59']"
                start-placeholder="签到时间（始）"
                end-placeholder="签到时间（止）"
              ></e6-date-picker>
            </el-form-item>

            <el-form-item class="search-item--buttons">
              <el-button type="primary" @click="handleSearch">查询</el-button>
              <el-button class="reset" @click="handleReset">重置</el-button>
            </el-form-item>
          </el-form>
        </template>
      </table-title>
      <!-- 表格 start -->
      <section class="table-wrapper">
        <el-table
          border
          :height="resizeViewHeight + 'px'"
          :data="tableData"
          highlight-current-row
          class="elTable"
          ref="elTable"
        >
          <!-- <el-table-column type="selection" width="40"></el-table-column> -->
          <!-- 序号 start -->
          <el-table-column
            label="序号"
            width="50"
            fixed="left"
            header-align="center"
            align="center"
          >
            <template slot-scope="scope">
              <span>
                {{
                  scope.$index +
                    1 +
                    (searchForm.pageIndex - 1) * searchForm.pageSize
                }}
              </span>
            </template>
          </el-table-column>
          <!-- 序号 end -->
          <el-table-column
            show-overflow-tooltip
            v-for="(column, index) in columnData"
            :key="index"
            :prop="column.fieldName"
            :label="column.fieldLabel"
            :min-width="column.width"
            :fixed="column.fixed"
            :align="column.align"
            header-align="center"
          >
            <template slot-scope="{ row }">
              <div class="table-label" v-if="column.fieldName === 'label'">
                <span
                  v-for="(item, index) in row.label"
                  :key="index"
                  class="label-box"
                  >{{ item }}</span
                >
              </div>
              <span
                v-else-if="column.fieldName === 'status'"
                :class="[
                  'status',
                  row.status === 1
                    ? 'finish'
                    : row.status === 2
                    ? 'ongoing'
                    : ''
                ]"
              >
                已完成
              </span>
              <span v-else-if="column.fieldName === 'taskNumber'">
                <el-button type="text" @click="handleGoToDetail(row)">
                  {{ row[column.fieldName] }}
                </el-button>
              </span>
              <span v-else>{{ row[column.fieldName] }}</span>
            </template>
          </el-table-column>
          <el-table-column
            fixed="right"
            align="center"
            label="操作"
            width="150"
          >
            <template slot-scope="scope">
              <e6-td-operate
                :data="getOperateList(scope.row)"
                @command="handleOperate($event, scope.row)"
              ></e6-td-operate>
            </template>
          </el-table-column>
        </el-table>
      </section>
      <!-- 表格 end -->

      <!-- 分页 start -->
      <section class="pagination-wrapper fixed-section" ref="paginationWrapper">
        <el-pagination
          :page-size.sync="searchForm.pageSize"
          :current-page.sync="searchForm.pageIndex"
          :page-sizes="pageSizes"
          :layout="layout"
          :total="total"
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        ></el-pagination>
      </section>
      <!-- 分页 end -->
    </div>
    <!-- 查看异常完成原因 -->
    <abnormal-reason
      :reasonVisible="reasonVisible"
      :reasonLabel="reasonLabel"
      :title="dialogTitle"
      :dialogLoading="dialogLoading"
      :reasonForm="reasonForm"
      @handleClose="handleClose"
    ></abnormal-reason>
    <!-- 异常完成输入 -->
    <abnormal-finish-dialog
      :abnormalFinishDialog="abnormalFinishDialog"
      abnormalType="2"
      :scheduleId="scheduleId"
      @handleClose="handleClose"
      @refreshData="queryList"
    ></abnormal-finish-dialog>
    <!-- 导出弹框 -->
    <export-dialog
      :exportVisible.sync="exportVisible"
      @confirm="handleExport"
      @refreshData="queryList"
      ref="exportDialog"
      :tableId="tableId"
    ></export-dialog>
  </div>
</template>

<script>
import base from "@/mixins/base";
import listPage from "@/mixins/list-page";
import listPageReszie from "@/mixins/list-page-resize";
import { printError } from "@/utils/util";
import exportDialog from "@/components/exportDialog/index";
import exportData from "@/mixins/exportData";
import {
  getScheduleInfoList,
  findDownList,
  getEngineer,
  taskInfoAbnormal,
  exportSchedule
} from "@/api";
import AbnormalFinishDialog from "@/components/workOrderManage/abnormalFinishDialog.vue";
import AbnormalReason from "@/components/workOrderManage/abnormalReason.vue";
export default {
  name: "allSchedulingInfo",
  data() {
    return {
      loading: false,
      searchForm: {
        scheduleInfoId: "", //调度信息ID
        scheduleInfoStatusList: ["1", "2", "3", "4"], //调度信息状态
        engineerrpId: "", //工程师
        engineerrpPhone: "", //工程师电话
        corpName: "", //客户名称
        createByName: "", //创建人
        taskNumber: "", //所属任务单号
        scheduleTaskId: "", //所属调度任务ID
        taskSecondClass: [], //任务单类型
        expectTime: [], //要求完成时间
        expecttimeStart: "", //期待解决时间开始
        expecttimeend: "", //期待解决时间结束
        createTime: [], //创建时间
        createdTimeStart: "", //期待解决时间开始
        createdTimeEnd: "", //期待解决时间结束
        finishTime: [], //完成时间
        finishTimeStart: "", //期待解决时间开始
        finishTimeEnd: "", //期待解决时间结束
        signTime: [], //签到时间
        signdateStart: "", //签到时间开始
        signdateEnd: "", //签到时间结束
        pageIndex: 1,
        pageSize: 20
      },
      scheduleStatusList: [], //调度信息状态下拉框
      taskSecondClassList: [], //任务单类型
      corpList: [], //客户名称
      engineerList: [], //工程师下拉框
      tableId: 8012,
      total: 0,
      columnData: [
        {
          fieldName: "taskNumber",
          display: true,
          fieldLabel: "所属任务单号",
          width: 180,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "corpName",
          display: true,
          fieldLabel: "客户名称",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "taskSecondClassName",
          display: true,
          fieldLabel: "任务单类型",
          width: 100,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "scheduleTaskId",
          display: true,
          fieldLabel: "所属调度任务ID",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "scheduleInfoId",
          display: true,
          fieldLabel: "调度信息ID",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center",
          class: ""
        },
        {
          fieldName: "isNeedVisit",
          display: true,
          fieldLabel: "是否需要上门",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center",
          class: ""
        },
        {
          fieldName: "scheduleInfoStatusName",
          display: true,
          fieldLabel: "调度信息状态",
          width: 140,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "engineerrpName",
          display: true,
          fieldLabel: "工程师",
          width: 100,
          disabled: false,
          fixed: false,
          align: "center",
          mode: "color",
          color: "#79a3f2"
        },
        {
          fieldName: "taskArrangedate",
          display: true,
          fieldLabel: "约定时间",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "signdate",
          display: true,
          fieldLabel: "签到时间",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "finishTime",
          display: true,
          fieldLabel: "调度信息完成时间",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "bigAreaName",
          display: true,
          fieldLabel: "所属大区",
          width: 100,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "areaName",
          display: true,
          fieldLabel: "所属片区",
          width: 100,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "createByName",
          display: true,
          fieldLabel: "调度信息创建人",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "createdTime",
          display: true,
          fieldLabel: "调度信息创建时间",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "corpProp",
          display: true,
          fieldLabel: "客户属性",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "project",
          display: true,
          fieldLabel: "所属项目",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "installCount",
          display: true,
          fieldLabel: "任务项总数",
          width: 140,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "engineerrpPhone",
          display: true,
          fieldLabel: "工程师电话",
          width: 120,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "waitWork",
          display: true,
          fieldLabel: "待作业",
          width: 80,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "workingCount",
          display: true,
          fieldLabel: "作业中",
          width: 80,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "finishCount",
          display: true,
          fieldLabel: "已完成",
          width: 80,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "waitVerify",
          display: true,
          fieldLabel: "待审核",
          width: 80,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "abnormalFinishCount",
          display: true,
          fieldLabel: "异常完成",
          width: 80,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "remark",
          display: true,
          fieldLabel: "备注",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        }
      ], //表头
      tableData: [], // 表格数据
      /******查看异常完成原因*********/
      scheduleId: "",
      reasonVisible: false, //异常完成原因
      reasonForm: {
        reason: "",
        remark: ""
      },
      dialogTitle: "查看异常原因",
      reasonLabel: "异常原因",
      /*****异常完成填写弹框*********/
      abnormalFinishDialog: false,
      scheduleInfoId: "",
      dialogLoading: false,
      exportApi: exportSchedule
    };
  },
  mixins: [listPage, base, listPageReszie, exportData],
  components: {
    AbnormalFinishDialog,
    AbnormalReason,
    exportDialog
  },
  computed: {},
  watch: {
    // 期望解决时间
    "searchForm.expectTime": {
      immediate: true,
      handler(val) {
        if (val[0] && val[1] && val[0] > val[1]) {
          this.searchForm.expectTime = this.searchForm.expectTime.reverse();
        }
        this.searchForm.expecttimeStart = val[0] || "";
        this.searchForm.expecttimeend = val[1] || "";
      }
    },
    // 创建时间
    "searchForm.createTime": {
      immediate: true,
      handler(val) {
        if (val[0] && val[1] && val[0] > val[1]) {
          this.searchForm.createTime = this.searchForm.createTime.reverse();
        }
        this.searchForm.createdTimeStart = val[0] || "";
        this.searchForm.createdTimeEnd = val[1] || "";
      }
    },
    // 完成时间
    "searchForm.finishTime": {
      immediate: true,
      handler(val) {
        if (val[0] && val[1] && val[0] > val[1]) {
          this.searchForm.finishTime = this.searchForm.finishTime.reverse();
        }
        this.searchForm.finishTimeStart = val[0] || "";
        this.searchForm.finishTimeEnd = val[1] || "";
      }
    },
    // 完成时间
    "searchForm.signTime": {
      immediate: true,
      handler(val) {
        if (val[0] && val[1] && val[0] > val[1]) {
          this.searchForm.signTime = this.searchForm.signTime.reverse();
        }
        this.searchForm.signdateStart = val[0] || "";
        this.searchForm.signdateEnd = val[1] || "";
      }
    }
  },
  created() {
    this.initData();
    this.queryList();
  },
  mounted() {
    this.setTableScrollDOM("elTable");
  },
  activated() {
    this.setScrollRecord();
  },
  methods: {
    //客户下拉框处理逻辑
    handleLoadCorpFilter: _.debounce(async function(val) {
      if (val) {
        this.searchForm.corpId = "";
        this.loadCorpData(val);
      }
    }, 300),
    // 初始化数据(获取表头,表格数据)
    async initData() {
      try {
        let promiseList = [
          findDownList(["scheduleStatus", "taskSecondClass"]),
          getEngineer({})
        ];
        let [taskTypeRes, engineerRes] = await Promise.all(promiseList);
        //调度信息状态下拉框
        this.scheduleStatusList = this.getFreezeResponse(taskTypeRes, {
          path: "data.scheduleStatus"
        });
        //任务单类型
        this.taskSecondClassList = this.getFreezeResponse(taskTypeRes, {
          path: "data.taskSecondClass"
        });
        //获取工程师下拉框名称
        this.engineerList = this.getFreezeResponse(engineerRes, {
          path: "data"
        });
      } catch (error) {
        printError(error);
      }
    },
    //查询全部调度信息
    async queryList() {
      try {
        this.loading = true;
        if (!this.searchForm.scheduleInfoId) {
          delete this.searchForm.scheduleInfoId;
        }
        if (!this.searchForm.scheduleTaskId) {
          delete this.searchForm.scheduleTaskId;
        }
        let res = await getScheduleInfoList(this.searchForm);
        this.tableData = this.getFreezeResponse(res, {
          path: "data.array"
        });
        this.total = this.getFreezeResponse(res, {
          path: "data.totalRecords"
        });
        this.$nextTick(() => {
          this.$refs.elTable.doLayout();
        });
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    // 日志
    toRecord() {
      this.routerPush({
        name: "workOrderScheduleInfoRecord",
        params: {
          refresh: true
        },
        query: {}
      });
    },
    // 获取页面操作权限
    getOperateList(row) {
      // 页面按钮权限
      let operates = [
        {
          label: "详情",
          id: 1
        }
      ];
      if (row.scheduleInfoStatus == 6) {
        operates.push({
          label: "查看异常完成原因",
          id: 2
        });
      }
      let status = [5, 6, 7, 8];
      if (
        status.findIndex(item => {
          return row.scheduleInfoStatus == item;
        }) == -1
      ) {
        operates.push({
          label: "异常完成",
          id: 3
        });
      }
      if (row.scheduleInfoStatus == 7) {
        operates.push({
          label: "查看拒绝原因",
          id: 4
        });
      }
      return operates;
      // return this.filterOperateBtn(operates);
    },
    // 操作--更多
    handleOperate(val, row) {
      if (val.id == 1) {
        this.routerPush({
          name: "taskListManage/schedulingInfoDetails",
          params: {
            refresh: true,
            scheduleInfoId: row.scheduleInfoId,
            taskId: row.taskId
          }
        });
      }
      //查看异常原因
      if (val.id == 2) {
        this.reasonVisible = true;
        this.dialogTitle = "查看异常原因";
        this.reasonLabel = "异常原因";
        this.getTaskInfoAbnormal(row.scheduleInfoId);
      }
      //异常完成
      if (val.id == 3) {
        this.abnormalFinishDialog = true;
        this.scheduleId = row.scheduleInfoId;
      }
      if (val.id == 4) {
        //查看拒绝原因
        this.dialogTitle = "查看拒绝原因";
        this.reasonLabel = "拒绝原因";
        this.reasonVisible = true;
        this.getTaskInfoAbnormal(row.scheduleInfoId);
      }
    },
    //调度信息---查看异常完成原因
    async getTaskInfoAbnormal(scheduleInfoId) {
      try {
        this.dialogLoading = true;
        let res = await taskInfoAbnormal({
          scheduleInfoId
        });
        if (res.code == "OK") {
          this.reasonForm = res.data;
          this.reasonVisible = true;
        }
      } catch (error) {
        printError(error);
      } finally {
        this.dialogLoading = false;
      }
    },
    //去任务单详情
    handleGoToDetail(row) {
      this.routerPush({
        name: "taskListManage/detail",
        params: {
          refresh: true,
          taskId: row.taskId
        }
      });
    },
    handleClose(name) {
      this[name] = false;
      this.$emit("refreshData");
    }
  }
};
</script>
<style lang="scss" scoped>
.table-label {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  .label-box {
    padding: 0px 10px;
    border-radius: 20px;
    display: inline-block;
    border: 1px solid #46bfea;
    color: #46bfea;
    margin: 5px;
  }
}
.status {
  display: inline-block;
  padding-left: 10px;
  padding-right: 10px;
  box-sizing: border-box;
  background: #2791f7;
  border-radius: 20px;
  border: 1px solid #2791f7;
  color: #fff;
}
.finish {
  background: #e19b39;
  border: 1px solid #e19b39;
}
.ongoing {
  background: #69c43b;
  border: 1px solid #69c43b;
}
</style>
